import type { LogsInitConfiguration } from '@datadog/browser-logs';
import type pino from 'pino';
import type { PrettyOptions } from 'pino-pretty';
import type { Options as PinoDatadogOptions } from 'pino-datadog';
import type pinoSentry from 'pino-sentry';
import type { DDTransportOptions } from 'datadog-transport-common';

export interface LoggerOptions {
  /// Disable redaction, which currently requires eval, so can't be used without
  /// `unsafe-eval` in the CSP, e.g. in the webviews
  /// https://github.com/davidmarkclements/fast-redact/issues/68
  noRedact?: boolean;

  // Node
  stdout?: boolean;
  stderr?: boolean;
  stdoutPretty?: boolean;
  stderrPretty?: boolean;
  prettyOptions?: PrettyOptions;
  datadogNode?: PinoDatadogOptions | false;
  datadogSafeOnly?: boolean;
  datadogTransportNode?: DDTransportOptions | false;
  datadogTransportEntry?: string;
  sentryNode?: pinoSentry.PinoSentryOptions;
  file?: {
    name: string;
  };
  omitHostnamePID?: boolean;
  streams?: (pino.DestinationStream | pino.StreamEntry)[];

  // Browser
  console?: boolean;
  datadogBrowser?: LogsInitConfiguration | false;
}

// Redacted paths to remove from the webapp, cloud functions and CLI.
// For the IDE, define redactions in ide/extensions/vscode/src/logger.ts
export const loggerRedactedPaths = [
  'err.config.data',
  'err.request.headers.authorization',
  'error.config.data',
  'err.config.body',
  'err.response.config.body',
  'err.response.config.data',
  'body.accessToken',
  'body.installation.accessToken',
  'body.companyName',
  'body.email',
  'body.password',
  'body.recaptchaToken',
  'body.paymentMethod',
  'body.pull_request',
  'body.taxId',
  'body.token',
  'req.headers.authorization',
  'body.snippetContent',
  'body.swmdContent',
  'body.swmFile',
  'body.usecaseRawContent',
  'body.textInput',
  'body.readmeContents',
  'body.snippets',
  'error.request.__sentry_xhr_v2__',
  'options.body',
  'options.headers.authorization',
];
