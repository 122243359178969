import { hostUrl } from '@/config';
import * as firestoreWrapper from '@/adapters-common/firestore-wrapper';
import {
  AiClient,
  OnPremAiClient,
  type WorkspaceSettings,
  firestoreCollectionNames,
  getLoggerNew,
} from '@swimm/shared';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';

const logger = getLoggerNew(__modulename);

export default class WebAiClient {
  private static _instance: AiClient;
  private static _workspaceId: string;

  private constructor() {
    // empty constructor
  }

  public static async getInstance(workspaceId: string): Promise<AiClient> {
    if (workspaceId !== this._workspaceId) {
      this._instance = null;
      this._workspaceId = workspaceId;
    }
    if (!this._instance) {
      try {
        const workspaceData = await firestoreWrapper.getDocRecursive(
          [firestoreCollectionNames.WORKSPACES, this._workspaceId],
          { addId: true }
        );
        const settings = workspaceData?.settings as WorkspaceSettings;
        if (settings?.onprem_agent_endpoint) {
          this._instance = new OnPremAiClient(
            CloudFunctions.getAuthToken,
            `${settings.onprem_agent_endpoint}/agent-api`,
            hostUrl
          );
        } else if (settings?.onprem_openai_endpoint) {
          this._instance = new OnPremAiClient(
            CloudFunctions.getAuthToken,
            `${settings.onprem_openai_endpoint}/ask-swimm-backend`,
            hostUrl
          );
        } else {
          this._instance = new AiClient(CloudFunctions.getAuthToken, `${hostUrl}/agent-api`);
        }
      } catch (err) {
        logger.error({ err }, `Error instantiating ai client. workspaceId:${this._workspaceId}`);
        throw err;
      }
    }
    return this._instance;
  }
}
