import { useAppModalsStore } from '@/modules/core/stores/modals-store';
import { callCompleteText, callGenerateText } from '@/modules/generative-ai/genAIClient';
import {
  type DbWrapperInterface,
  type GenerativeAiInterface,
  getGenerateSnippetCommentRequestParams,
  getGenerateTextModifiersRequestParams,
} from '@swimm/editor';
import {
  COMPLETE_TEXT_ENDPOINT,
  type ChatTextCompletionPrompt,
  type GenerateMermaidRequest,
  type GenerateMermaidResponse,
  type GenerateSnippetCommentResponse,
  type GenerateTextCompletionResponse,
  type GenerateTextModifiersResponse,
  GenerativeAiRequestType,
  type GenerativeAiResponse,
  type LLMResponseFormat,
  type TextCompletionParams,
  type TextCompletionPrompt,
  type WorkspaceSettings,
  firestoreCollectionNames,
  getGenerateMermaidRequestParams,
  getLoggerNew,
  isRepoIdDummyRepo,
} from '@swimm/shared';
import { storeToRefs } from 'pinia';
import { useStore } from 'vuex';
import { SWIMM_ONPREM_AGENT_CLOUD_RUN_URL } from '@/config';

const logger = getLoggerNew(__modulename);

export function useGenerativeAi(dbWrapper: DbWrapperInterface): GenerativeAiInterface {
  const { showRepoSettingsModal } = storeToRefs(useAppModalsStore());
  const store = useStore();
  const onpremEndpointPerWorkspace = {};

  async function isAIGenerationEnabledForRepo(repoId: string): Promise<boolean> {
    if (isRepoIdDummyRepo(repoId)) {
      return true;
    }
    try {
      await store.dispatch('database/updateRepoMetadata', { repoId, force: true });
      return store.getters['database/db_getRepoMetadata'](repoId)?.integrations?.generative_ai_enabled ?? false;
    } catch (err) {
      logger.error({ err }, 'Error checking if AI generation is enabled for repo');
    }
    return false;
  }
  async function getGenAIEndpoint(workspaceId: string): Promise<string> {
    if (onpremEndpointPerWorkspace[workspaceId]) {
      return onpremEndpointPerWorkspace[workspaceId];
    }
    let endpoint = SWIMM_ONPREM_AGENT_CLOUD_RUN_URL;
    try {
      if (dbWrapper) {
        const workspaceData = await dbWrapper.getDoc([firestoreCollectionNames.WORKSPACES, workspaceId]);
        const settings = workspaceData?.settings as WorkspaceSettings;
        endpoint = settings?.onprem_agent_endpoint
          ? `${settings?.onprem_agent_endpoint}/agent-api`
          : settings?.onprem_openai_endpoint
          ? `${settings?.onprem_openai_endpoint}/ask-swimm-backend`
          : endpoint;
        onpremEndpointPerWorkspace[workspaceId] = endpoint;
        return endpoint;
      }
      throw new Error('No dbWrapper, reverting to default endpoint.');
    } catch (err) {
      logger.error({ err }, `Error getting generative AI endpoint. workspaceId:${workspaceId}`);
      return endpoint;
    }
  }

  function openGenerativeAiRepoSettings() {
    showRepoSettingsModal.value = true;
  }

  async function generateTextModifier(request: {
    repoId: string;
    workspaceId: string;
    textInput: string;
    modifier: string;
  }): Promise<
    | (GenerativeAiResponse & GenerateTextModifiersResponse)
    | (GenerativeAiResponse & {
        status: 'error';
      })
  > {
    if (!request.modifier || !request.textInput) {
      logger.error(
        `Error generating text modifier. The request is missing data. repoId:${request.repoId};workspaceId:${request.workspaceId};`
      );
      return { status: 'error' };
    }

    const result: LLMResponseFormat = await callGenerateText(
      getGenerateTextModifiersRequestParams({ ...request, type: GenerativeAiRequestType.GENERATE_TEXT_MODIFIER }),
      await getGenAIEndpoint(request.workspaceId)
    );
    if (!result.generatedText || result.error) {
      return { status: 'error' };
    }

    return {
      status: 'success',
      type: GenerativeAiRequestType.GENERATE_TEXT_MODIFIER,
      generatedText: result.generatedText,
    };
  }

  async function generateSnippetComment(request: {
    repoId: string;
    workspaceId: string;
    snippetContent: string;
  }): Promise<
    | (GenerativeAiResponse & GenerateSnippetCommentResponse)
    | (GenerativeAiResponse & {
        status: 'error';
      })
  > {
    if (!request.snippetContent) {
      logger.error(
        `Error generating snippet comment. The request is missing snippet data. repoId:${request.repoId};workspaceId:${request.workspaceId};`
      );
      return { status: 'error' };
    }
    const requestParams = getGenerateSnippetCommentRequestParams({
      ...request,
      type: GenerativeAiRequestType.GENERATE_SNIPPET_COMMENT,
    });
    const result = await callGenerateText(requestParams, await getGenAIEndpoint(request.workspaceId));
    if (!result.generatedText || result.error) {
      return { status: 'error' };
    }
    return {
      status: 'success',
      type: GenerativeAiRequestType.GENERATE_SNIPPET_COMMENT,
      generatedText: result.generatedText,
    };
  }

  async function generateMermaid(request: Omit<GenerateMermaidRequest, 'type'>): Promise<
    | (GenerativeAiResponse & GenerateMermaidResponse)
    | (GenerativeAiResponse & {
        status: 'error';
      })
  > {
    if (!request.userPrompt) {
      logger.error(
        `Error generating mermaid. The request is missing before and after or userPrompt. repoId:${request.repoId};workspaceId:${request.workspaceId};`
      );
      return { status: 'error' };
    }
    const requestParams = getGenerateMermaidRequestParams({
      ...request,
      type: GenerativeAiRequestType.GENERATE_MERMAID,
    });
    const result = await callGenerateText(requestParams, await getGenAIEndpoint(request.workspaceId), 'GPT_4_SHORT');
    if (!result?.generatedText || result.error) {
      return { status: 'error' };
    }
    return {
      status: 'success',
      type: GenerativeAiRequestType.GENERATE_MERMAID,
      generatedMermaid: result.generatedText,
    };
  }

  async function generateTextCompletion(request: {
    repoId: string;
    workspaceId: string;
    prompt: TextCompletionPrompt | ChatTextCompletionPrompt;
    textCompletionParams: TextCompletionParams;
  }): Promise<
    | (GenerativeAiResponse & GenerateTextCompletionResponse)
    | (GenerativeAiResponse & {
        status: 'error';
      })
  > {
    const result = await callCompleteText(
      request.textCompletionParams,
      await getGenAIEndpoint(request.workspaceId),
      request.workspaceId,
      COMPLETE_TEXT_ENDPOINT
    );
    if (!('generatedText' in result) || !result.generatedText) {
      throw new Error('Error completing text: Returned empty completion');
    }
    return {
      status: 'success',
      type: GenerativeAiRequestType.GENERATE_TEXT_COMPLETION,
      generatedText: result.generatedText,
      cost: result.cost,
    };
  }
  return {
    generateTextCompletion,
    isAIGenerationEnabledForRepo,
    openGenerativeAiRepoSettings,
    generateTextModifier,
    generateSnippetComment,
    generateMermaid,
    getGenAIEndpoint,
  };
}
