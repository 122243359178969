<script setup lang="ts">
import {
  BaseBlock,
  BaseButton,
  BaseDropdown,
  BaseHeading,
  BaseIcon,
  BaseLayoutGap,
  BaseProse,
  BaseTextarea,
  BaseTooltip,
  MenuItem,
  type OptionType,
} from '@swimm/reefui';
import { SplitButton, SwModal } from '@swimm/ui';
import { PrData, ProviderTerminology, SnippetInfo, productEvents } from '@swimm/shared';
import { computed, ref } from 'vue';
import { GenerativeAIMessageBox } from '@swimm/editor';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { debounce } from 'lodash-es';

const analytics = useAnalytics();

const props = defineProps<{
  mergedPrs: PrData[];
  openPrs: PrData[];
  show: boolean;
  selectedPr: PrData | null;
  snippets: SnippetInfo[];
  customPrompt: string;
  genAiEnabledForRepo: boolean;
  genAiCapAvailable: boolean;
  workspaceId: string;
  repoId: string;
  isWorkspaceAdmin: boolean;
  prsLoading: boolean;
  providerTerminology: ProviderTerminology;
  groupSnippetsByFilePath: Record<string, SnippetInfo[]>;
}>();

const emit = defineEmits<{
  (e: 'pr-selected', pr: PrData): void;
  (e: 'remove-file-snippets', filePath: string): void;
  (e: 'look-for', prId: string);
  (e: 'add-to-doc'): void;
  (e: 'generate-with-ai'): void;
  (e: 'edit-snippets'): void;
  (e: 'update-custom-prompt', customPrompt: string): void;
  (e: 'close'): void;
  (e: 'clicked-repo-settings'): void;
}>();

const totalFileCount = computed(() => {
  return Object.keys(props.groupSnippetsByFilePath).length;
});

const PRsToDropDownOptions = computed(() => {
  const mergedGroup: OptionType[] = props.mergedPrs.map((pr) => {
    return {
      id: pr.prId,
      icon: 'branch-merged-pr',
      name: pr.title,
      link: pr.url,
      metadata: {
        icon: 'doc-file-change',
        count: pr.filesWithAdditions,
        tooltip: 'Files with additions',
      },
    };
  });
  if (!props.openPrs.length) {
    return mergedGroup;
  }
  const openGroup: OptionType[] = props.openPrs.map((pr) => {
    return {
      id: pr.prId,
      icon: 'pr',
      name: pr.title,
      link: pr.url,
      metadata: {
        icon: 'doc-file-change',
        count: pr.filesWithAdditions,
        tooltip: 'Files with additions',
      },
    };
  });

  return {
    'On current branch': openGroup,
    [`Merged ${props.providerTerminology.pullRequestShort}S`]: mergedGroup,
  };
});

const filteredOptiosCount = ref(0);
const isNumericRegex = /^[0-9]+$/;
const filterQuery = ref('');

function selectedPR(prOption: OptionType) {
  const prId = prOption.id;
  const prData = props.mergedPrs.concat(props.openPrs).filter((pr) => pr.prId === prId)[0];
  analytics.track(productEvents.PICKED_PR, {
    'Result Count': filteredOptiosCount.value,
    'Is Numeric': isNumericRegex.test(filterQuery.value),
    'Has Filter Term': filterQuery.value.length > 0,
  });
  emit('pr-selected', prData);
}

const generateWithAiDisabledTooltip = computed(() => {
  if (!props.genAiCapAvailable) {
    return "You've exceeded your workspace's Generative AI query quota. Contact support via info@swimm.io for more info.";
  }
  if (!props.genAiEnabledForRepo) {
    return 'Generative AI is not enabled for this repository.';
  }
  if (noSnippetsSelected.value) {
    return 'No snippets selected to generate a document from.';
  }
  return '';
});

const addToDocsDisabled = computed(() => {
  if (noSnippetsSelected.value) {
    return 'No snippets selected to generate a document from.';
  }

  return '';
});

const noSnippetsSelected = computed(() => {
  return props.snippets.length === 0;
});

function trackDropdownFiltering(query) {
  filterQuery.value = query;
  analytics.track(productEvents.FILTERED_PR_PICKER, {
    'Is Numeric': isNumericRegex.test(query),
    'Results Count': filteredOptiosCount.value,
  });
}
const debouncedTrackFiltering = debounce(trackDropdownFiltering, 1000);
</script>

<template>
  <SwModal
    :show-modal="show"
    :heading="`${providerTerminology.pullRequestShort} 2 Doc`"
    :padded="false"
    @close="emit('close')"
  >
    <template #header>
      <BaseLayoutGap alignment="left">
        <BaseIcon name="magic" />
        <BaseProse weight="bolder">
          {{ `${providerTerminology.pullRequestShort} to doc` }}
        </BaseProse>
      </BaseLayoutGap>
    </template>
    <div class="prs-modal">
      <BaseLayoutGap direction="column" size="medium" class="pr-modal-content">
        <BaseLayoutGap direction="column" size="xxsmall" alignment="stretch">
          <BaseLayoutGap direction="row" alignment="left">
            <BaseHeading :level="4">
              {{ `${providerTerminology.pullRequestShort} name or number` }}<span class="required">*</span>
            </BaseHeading>
            <BaseTooltip>
              <template #content>
                <img src="../../../assets/pr-number-helper.svg" alt="pr-number-helper" />
              </template>
              <BaseIcon name="info-outline" />
            </BaseTooltip>
          </BaseLayoutGap>
          <BaseDropdown
            :options="PRsToDropDownOptions"
            :footer="true"
            :display-footer="(_showFooter, query) => isNumericRegex.test(query)"
            :default-option-selected="selectedPr?.prId"
            :fetching="prsLoading"
            :no-options-message="`Couldn't find your ${providerTerminology.pullRequestShort}? Search using ${providerTerminology.pullRequestShort} number to find older ${providerTerminology.pullRequestShort}s`"
            :fetching-message="`Loading ${providerTerminology.pullRequestShort}s...`"
            :placeholder="`Search ${providerTerminology.pullRequestShort} name or number`"
            focus-on-mount
            @selected="selectedPR"
            @search="emit('look-for', $event)"
            @open="analytics.track(productEvents.OPENED_PR_PICKER)"
            @query="debouncedTrackFiltering"
            @filtered-options="(options) => (filteredOptiosCount = options.length)"
          />
        </BaseLayoutGap>
        <BaseLayoutGap v-if="selectedPr" direction="column" size="small" alignment="stretch">
          <BaseBlock v-if="snippets.length === 0" variant="warning" data-testid="no-snippets-warning">
            <BaseProse variant="warning" weight="bold" size="small">No file changes selected for creation </BaseProse>
            <BaseProse variant="warning" size="small"
              >{{
                `You need at least one change from a ${providerTerminology.pullRequestShort} to create a doc. Make sure the selected ${providerTerminology.pullRequestShort} contains code changes.`
              }}
            </BaseProse>
          </BaseBlock>
          <template v-if="snippets.length > 0">
            <BaseHeading :level="4" class="files-preview-title">
              {{ snippets.length }} {{ snippets.length === 1 ? 'snippet' : 'snippets' }} from {{ totalFileCount }}
              {{ totalFileCount === 1 ? 'file' : 'files' }}:
            </BaseHeading>
            <BaseLayoutGap direction="column" size="xsmall" alignment="stretch">
              <BaseLayoutGap
                v-for="(fileSnippets, filePath) in groupSnippetsByFilePath"
                :key="filePath"
                direction="row"
                alignment="stretch"
                class="file-preview"
                data-testid="file-changes"
              >
                <BaseLayoutGap direction="row" size="xsmall" class="file-item">
                  <BaseIcon name="file" />
                  <div class="file-path">
                    <BaseProse
                      class="link text-ellipsis text-reverse-ellipsis"
                      v-tooltip="{ content: filePath, placement: 'right' }"
                    >
                      {{ filePath }}
                    </BaseProse>
                  </div>
                  <BaseProse variant="secondary" size="xsmall">
                    ({{ fileSnippets.length }} {{ fileSnippets.length === 1 ? 'change' : 'changes' }})</BaseProse
                  >
                </BaseLayoutGap>
                <BaseButton
                  variant="tertiary"
                  size="small"
                  @click="emit('remove-file-snippets', filePath)"
                  v-tooltip="'Remove all snippets from this file'"
                >
                  <template #leftIcon>
                    <BaseIcon name="close" />
                  </template>
                </BaseButton>
              </BaseLayoutGap>
            </BaseLayoutGap>
          </template>
        </BaseLayoutGap>
        <BaseLayoutGap v-if="selectedPr" direction="column" class="textarea-container">
          <BaseHeading :level="4">Instructions for AI</BaseHeading>
          <BaseTextarea
            :model-value="customPrompt"
            @update:model-value="emit('update-custom-prompt', $event)"
            class="input-textarea"
            :placeholder="'Please write the document based on the following table of contents:\n  1. Introduction\n  2. Code flow\n  3. Conclusion'"
          />
        </BaseLayoutGap>
        <GenerativeAIMessageBox
          v-if="!genAiEnabledForRepo"
          :workspace-id="workspaceId"
          :repo-id="repoId"
          :is-workspace-admin="isWorkspaceAdmin"
          data-testid="generative-ai-message-box"
          @clicked-repo-settings="emit('clicked-repo-settings')"
        >
        </GenerativeAIMessageBox>
        <BaseLayoutGap direction="row" alignment="right" class="buttons-container">
          <BaseTooltip :content="addToDocsDisabled">
            <BaseButton
              variant="secondary"
              @click="emit('add-to-doc')"
              :disabled="noSnippetsSelected"
              data-testid="create"
              >Add to doc only</BaseButton
            >
          </BaseTooltip>
          <BaseTooltip :content="generateWithAiDisabledTooltip">
            <SplitButton
              more-icon="arrow-down"
              variant="magic"
              :disabled="noSnippetsSelected || !genAiEnabledForRepo || !genAiCapAvailable"
              @click="emit('generate-with-ai')"
              data-testid="create-with-ai"
              @opened="analytics.track(productEvents.CLICKED_BUTTON_OPTIONS_PR2DOC)"
            >
              Generate with AI
              <template #options>
                <div class="options">
                  <MenuItem @click="emit('edit-snippets')">Edit snippets before generating</MenuItem>
                </div>
              </template></SplitButton
            >
          </BaseTooltip>
        </BaseLayoutGap>
      </BaseLayoutGap>
    </div>
  </SwModal>
</template>

<style scoped>
.icon.icon-magic {
  color: var(--text-color-magic-strong);
}

.required {
  color: var(--color-text-danger);
}

.prs-modal {
  padding: var(--space-medium);
  width: 540px;
  display: flex;
  flex-direction: column;
  gap: var(--space-small);

  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-small);

    :deep(.button) {
      height: 32px;
      box-sizing: border-box;
    }
  }

  .custom-prompt {
    width: 100%;

    &__activation {
      width: 100%;

      &__input-checkbox:focus {
        outline: none;
      }

      &__example {
        font-style: italic;
        margin: 0 var(--space-md);
      }
    }

    &__input-textarea {
      width: inherit;
      height: 120px;
    }
  }
}

.file-path {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.files-preview {
  width: 100%;
}

.file-preview {
  width: 100%;
  white-space: nowrap;
}

.buttons-container {
  width: 100%;
}

.textarea-container {
  width: 100%;
}

.input-textarea {
  width: 100%;
  min-height: 8rem;
}

.file-item {
  overflow: hidden;
}
</style>
