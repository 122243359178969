import { config } from '@swimm/shared';

export const AUTH_URL = process.env.AUTH_URL;
export const SLACK_APP_CLIENT_ID = process.env.SLACK_APP_CLIENT_ID;
export const DOCS_SITE_URL = 'https://docs.swimm.io';
export const GITHUB_CLIENT_ID = process.env.GITHUB_CLIENT_ID;
export const GITHUB_APP_AUTH_CLIENT_ID = process.env.GITHUB_APP_AUTH_CLIENT_ID;
export const SLACK_APP_ID = process.env.SLACK_APP_ID;
export const STIGG_KEY = process.env.STIGG_KEY;

export const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY;
export const GIPHY_API_KEY = process.env.GIPHY_API_KEY;
export const SWIMM_TERMS_OF_SERVICE_ADDRESS = `${config.SWIMM_WEBSITE_ADDRESS}/master-subscription-and-professional-services-agreement`;
export const SWIMM_PRIVACY_POLICY_ADDRESS = `${config.SWIMM_WEBSITE_ADDRESS}/legal/privacy-policy/`;
export const SWIMM_DOC_SITE_GETTING_STARTED = `${config.SWIMM_DOC_SITE}/getting-started-guide/`;
export const SWIMM_DOC_SITE_GIT_PROVIDERS_FAQ = `${config.SWIMM_DOC_SITE}/Git-providers/FAQ`;
export const SWIMM_DOC_SITE_SHARING_INTERNALLY = `${config.SWIMM_DOC_SITE}/Features/sharing-without-code-access`;
export const SWIMM_DOC_SITE_GENERATIVE_AI = `${config.SWIMM_DOC_SITE}/Swimm-AI/enable-swimm-AI`;
export const SWIMM_DOC_SITE_ASK_SWIMM = `${config.SWIMM_DOC_SITE}/swimm-ai/ask-swimm/`;
export const SWIMM_DOC_SITE_USERS_AND_BILLING = `${config.SWIMM_DOC_SITE}/authorization/users-and-billing`;
export const SWIMM_DOC_SITE_EXPORT_TO_PDF = `${config.SWIMM_DOC_SITE}/import-and-export/export-swimm-docs/`;
export const GITHUB_OAUTH_APP_SETTINGS = `https://github.com/settings/connections/applications/${GITHUB_CLIENT_ID}`;
export const SLACK_APP_INSTALLATION_LINK = `https://slack.com/oauth/v2/authorize?client_id=${SLACK_APP_CLIENT_ID}&scope=channels:read,chat:write,chat:write.customize,groups:read,groups:write,links:read,links:write,chat:write.public&state=${config.SLACK_APP_INSTALLATION_STATE}`;
export const CALENDLY_DEMO_LINK = 'https://calendly.com/ricardo-swimm/20-minute-onboarding';
export const CALENDLY_SALES_LINK = 'https://calendly.com/swimm-demo-team/your-call-with-a-swimm-expert';
export const CALENDLY_ENTERPRISE_HOSTING = 'https://calendly.com/swimm-demo-team/swimm-expert-enterprise-hosting';
export const PJSON_VERSION = (process.env.PJSON_VERSION || '').replace(/"/g, '');
export const isEmulated =
  process.env.VITE_MODE === 'emulated' || process.env.MODE === 'emulated' || !!process.env.FUNCTIONS_EMULATOR;

export const ENABLE_CONSOLE_LOGGER = !!process.env.ENABLE_CONSOLE_LOGGER;

export const isNodeEnv = typeof process !== 'undefined' && !!process.release && process.release.name === 'node';
export const isWebApp = !isNodeEnv; // When in the browser, the process has no nodeJS properties at all
const isCli = isNodeEnv && !!process.env.SWIMM_CLI;

export const isDevelopment = process.env.NODE_ENV === 'development' || !process.env.NODE_ENV;
export const isProduction = ['production', 'pack'].includes(process.env.NODE_ENV);

export const isStaging = !!process.env.IS_STAGING;
export const isTest = !!process.env.IS_SWIMM_TEST || process.env.NODE_ENV === 'test';
export const isAutomation = !!process.env.IS_AUTOMATION;
export const isStage = !!process.env.IS_STAGE;
export const hostUrl = getHostUrl();

// TODO Can't we just use import.meta.env.MODE...
export const SWIMM_ENV =
  (process.env.NODE_ENV === 'production' && 'production') || (!!isStage && 'stage') || 'development';

export const CLOUD_FUNCTIONS_HOST_STAGE_ENV = 'https://stag.swimm.cloud/functions';

export const CLOUD_FUNCTIONS_HOST = config.getCloudFunctionsDomain(SWIMM_ENV, isEmulated);

export const ilVatRateId = isProduction ? 'txr_1JSiN1JyU57dQRsUNw6GHcsJ' : 'txr_1JQA34JyU57dQRsUN1EMrmBA';
export const stripePublishableKey = isProduction
  ? 'pk_live_51IDVf8JyU57dQRsUb2SGrxqROgF4vOLYscGHXwQOgWTsOF809JkI1T9Pn0MDYKEgpUNaDDHYc2VGNEWjm4ZycOe5003xj6E1An'
  : 'pk_test_51IDVf8JyU57dQRsUKFmMtVGBoUjQvZY2zWzp2Lkm4Hx9ydBnb6xeny2DpJrfHWbGoKJcDRSlanTS1UGpK0OIejDY00LOOghcWN';

export const DATADOG_CLIENT_TOKEN = 'pub469a2a2a78407f58ead45a1580bce140';
export const DATADOG_SERVICE_NAME = process.env.DATADOG_SERVICE_NAME;
export const DATADOG_SITE = 'datadoghq.eu';
export const DATADOG_SOURCE = isWebApp ? 'browser' : 'nodejs';
export const DATADOG_SERVICE = `swimm-${
  DATADOG_SERVICE_NAME ? DATADOG_SERVICE_NAME : isWebApp ? 'web' : isCli ? 'cli' : isTest ? 'test' : 'unknown'
}`;

export const SWIMM_ONPREM_AGENT_CLOUD_RUN_URL = process.env.SWIMM_ONPREM_AGENT_CLOUD_RUN_URL;
export const SWIMM_BACKEND_CLOUD_RUN_URL = process.env.SWIMM_BACKEND_CLOUD_RUN_URL;

export const DATADOG_ENV = isProduction
  ? 'prod'
  : isStage
  ? 'stage'
  : isStaging
  ? 'staging'
  : isDevelopment
  ? 'dev'
  : process.env.NODE_ENV || 'unknown';

export const isRemoteLoggingEnabled = isProduction || (isStaging && !isAutomation) || (!isTest && !isAutomation);

// Returns a user-friendly version for displaying and printing
// Supports versions with structure: 0.1.3 , 0.2.0, 0.1.30, 0.1.31
export const getFriendlyVersion = function (version?: string) {
  const swimmVersion = version || PJSON_VERSION;
  const hyphenIndex = swimmVersion.indexOf('-');
  const pjsonVersionWithTrimmedHyphen = swimmVersion.substring(
    0,
    hyphenIndex === -1 ? swimmVersion.length : hyphenIndex
  );
  try {
    const versionSuffix = pjsonVersionWithTrimmedHyphen.slice(-2);
    if (versionSuffix.startsWith('.')) {
      return pjsonVersionWithTrimmedHyphen;
    }
    return versionSuffix.endsWith('0')
      ? pjsonVersionWithTrimmedHyphen.slice(0, -1)
      : `${pjsonVersionWithTrimmedHyphen.slice(0, -2)}${versionSuffix[0]}.${versionSuffix[1]}`;
  } catch (error) {
    return pjsonVersionWithTrimmedHyphen;
  }
};

function getHostUrl() {
  if (isProduction) {
    return 'https://app.swimm.io/';
  } else if (isStaging) {
    return 'https://staging.swimm.cloud/';
  } else if (isStage) {
    return 'https://stag.swimm.cloud/';
  } else if (isDevelopment) {
    return 'https://staging.swimm.cloud/';
  } else {
    return 'https://staging.swimm.cloud/'; // staging as default
  }
}
