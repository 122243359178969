<template>
  <div v-if="!loading" class="header" data-testid="top-bar">
    <div v-if="hideBreadcrumbs"></div>
    <BreadCrumbs3
      :hidden="hideBreadcrumbs"
      :appendix-label="breadCrumbsAppendixLabel"
      :appendix-icon="breadCrumbsAppendixIcon"
      :is-appendix-label-error="isBreadCrumbsAppendixLabelError"
      :show-workspace-breadcrumb="showWorkspaceBreadcrumb"
      :show-route-back="showRouteBack"
      @branch-changed="$emit('branch-changed')"
    >
      <slot name="breadcrumbs" />
    </BreadCrumbs3>
    <div v-if="isInternalEnv" class="internal-env" :class="internalEnvClass">{{ internalEnv }} Environment</div>
    <div class="submenu">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BreadCrumbs3 from '@/common/components/TopBar/BreadCrumbs3.vue';
import { isProduction, isStaging } from '@/config';
import { useNavigate } from '@/common/composables/navigate';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { storeToRefs } from 'pinia';

export default {
  components: { BreadCrumbs3 },
  props: {
    loading: { type: Boolean, default: false },
    hideBreadcrumbs: { type: Boolean },
    breadCrumbsAppendixLabel: { type: String, default: '' },
    breadCrumbsAppendixIcon: { type: String, default: '' },
    isBreadCrumbsAppendixLabelError: { type: Boolean, default: false },
    showWorkspaceBreadcrumb: { type: Boolean, default: true },
    showRouteBack: { type: Boolean, default: true },
  },
  emits: ['branch-changed'],
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const { navigateToPageAndTerminateWorker } = useNavigate();

    return { user, navigateToPageAndTerminateWorker };
  },
  data() {
    return {
      internalEnv: '',
    };
  },
  computed: {
    ...mapGetters('database', ['db_getWorkspace', 'db_getRepoMetadata']),
    isInternalEnv() {
      return !!this.internalEnv && this.internalEnv !== '';
    },
    internalEnvClass() {
      return this.isInternalEnv ? ['internal-env', isStaging ? 'staging' : 'dev'] : '';
    },
    repoMetadata() {
      return this.db_getRepoMetadata(this.$route.params.repoId);
    },
    hasWorkspaceInRoute() {
      return !!this.$route.params.workspaceId;
    },
    isClickableLogo() {
      return (
        this.$route.fullPath &&
        ((this.hasWorkspaceInRoute && !this.$route.fullPath.endsWith(this.$route.params.workspaceId)) ||
          this.$route.fullPath.includes('/workspaces/new') ||
          this.$route.fullPath.includes(`/workspaces/initRepo`))
      );
    },
  },
  created() {
    if (!isProduction) {
      this.internalEnv = isStaging ? 'Staging' : 'Development';
    }
  },
  methods: {
    routeHome() {
      const route = this.$route.params.workspaceId ? `/workspaces/${this.$route.params.workspaceId}` : '/';
      if (this.isClickableLogo && this.$route.fullPath !== route) {
        this.navigateToPageAndTerminateWorker({ newRoute: route });
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-base) var(--space-sm) var(--space-base) 0;
  font-size: var(--body-S);
  background: var(--color-bg);
  box-sizing: border-box;
  height: var(--top-header-height);
}

@media only screen and (min-width: 750px) {
  .version {
    display: inline;
  }
}

.submenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: fit-content;
  gap: var(--space-base);

  > :deep(:not(i)) {
    font-weight: bold;
  }
}

.internal-env {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 1234;
  width: 300px;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  font-size: 10px;
  text-align: center;
  transform: translateX(-50%);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dev {
  background-color: var(--color-status-success);
}

.staging {
  background-color: var(--color-status-warning);
}
</style>
