<template>
  <div class="editor" :class="{ 'editor-border': showBorder }">
    <EditorBubbleMenu
      v-if="!readOnly"
      :editor="editor"
      :push-bubble-menu="true"
      :handle-bottom-bubble-menu="false"
      :workspace-id="workspaceId"
      :repo-id="repoId"
      @set-as-link="setSelectionAsLink"
    />

    <SuggestionMenu ref="suggestionMenu" />
    <EditorContent class="editor-content" :editor="editor" />
  </div>
</template>

<script setup lang="ts">
import { callGenerateText } from '@/modules/generative-ai/genAIClient';
import { onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';
import EditorBubbleMenu from './EditorBubbleMenu.vue';
import {
  CodeMarkExtension,
  CustomCommandExtension,
  PasteMarkdownExtension,
  SuggestionMenu,
  SwimmCustomCommands,
  getDraggableItemConfig,
  pasteMarkdown,
  pastePlainText,
  useHTMLToMarkdown,
  useSwmContentToTiptap,
  useWindowHeight,
} from '@swimm/editor';

import { Link } from '@tiptap/extension-link';
import { SwmCellType, getLoggerNew } from '@swimm/shared';
import { generateHTML } from '@tiptap/html';
import { Editor, EditorContent } from '@tiptap/vue-3';

import BulletList from '@tiptap/extension-bullet-list';
import Heading from '@tiptap/extension-heading';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Blockquotefrom from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Dropcursor from '@tiptap/extension-dropcursor';
import HardBreak from '@tiptap/extension-hard-break';
import History from '@tiptap/extension-history';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Document from '@tiptap/extension-document';

import { debounce } from 'lodash-es';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { storeToRefs } from 'pinia';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { SWIMM_ONPREM_AGENT_CLOUD_RUN_URL } from '@/config';

const logger = getLoggerNew(__modulename);

const props = defineProps({
  markdown: { type: Array<{ type: SwmCellType.Text; text: string }>, default: () => [] },
  isWorkspace: { type: Boolean, default: false },
  repoId: { type: String, default: '' },
  workspaceId: { type: String, default: '' },
  showBorder: { type: Boolean, default: false },
  branch: { type: String, default: '' },
  readOnly: { type: Boolean, default: false },
  playlistId: { type: String, required: true },
});

const emit = defineEmits<{
  (e: 'markdown-changed', content: { type: SwmCellType.Text; text: string }[]);
}>();

provide('isPlaylist', true);
provide('swmId', props.playlistId);
const editor = ref(null);
const content = ref<{ type: SwmCellType.Text; text: string }[]>(props.markdown);
const analytics = useAnalytics();
const suggestionMenu = ref(null);
provide('analytics', analytics);
const { workspaceSettings } = storeToRefs(useWorkspaceStore());
provide('generateTextModifier', async (requestParams) =>
  callGenerateText(
    requestParams,
    workspaceSettings.value?.onprem_agent_endpoint
      ? `${workspaceSettings.value?.onprem_agent_endpoint}/agent-api`
      : workspaceSettings.value?.onprem_openai_endpoint
      ? `${workspaceSettings.value?.onprem_openai_endpoint}/ask-swimm-backend`
      : SWIMM_ONPREM_AGENT_CLOUD_RUN_URL
  )
);
// Tech debt
const tiptapContentFromSwmContent = useSwmContentToTiptap(props.repoId);

useWindowHeight(true);

onBeforeUnmount(() => {
  editor.value?.destroy();
});

onMounted(() => {
  // Extensions configuration
  const extensions = [
    suggestionMenu.value.commandsSuggestions,
    SwimmCustomCommands,

    Paragraph.extend(getDraggableItemConfig()),
    Heading.extend(getDraggableItemConfig()),
    BulletList.extend(getDraggableItemConfig()),

    ListItem.extend(getDraggableItemConfig()),
    OrderedList.extend(getDraggableItemConfig()),
    Bold,

    CustomCommandExtension.extend({ name: `QuotesExtension` }).configure({
      slashCommandName: 'quotes',
      commandEntered: () => {
        editor.value.chain().focus().toggleBlockquote().run();
      },
    }),
    CustomCommandExtension.extend({ name: 'paste markdown' }).configure({
      slashCommandName: 'paste markdown',
      commandEntered: () => {
        if (editor.value) {
          pasteMarkdown(editor.value);
        }
      },
    }),
    CustomCommandExtension.extend({ name: 'paste plain' }).configure({
      slashCommandName: 'paste plain',
      commandEntered: () => {
        if (editor.value) {
          pastePlainText(editor.value);
        }
      },
    }),
    CustomCommandExtension.extend({ name: `Heading 1` }).configure({
      slashCommandName: 'heading 1',
      commandEntered: () => {
        editor.value.chain().focus().toggleHeading({ level: 1 }).run();
      },
    }),
    CustomCommandExtension.extend({ name: `Heading 2` }).configure({
      slashCommandName: 'heading 2',
      commandEntered: () => {
        editor.value.chain().focus().toggleHeading({ level: 2 }).run();
      },
    }),
    CustomCommandExtension.extend({ name: `Heading 3` }).configure({
      slashCommandName: 'heading 3',
      commandEntered: () => {
        editor.value.chain().focus().toggleHeading({ level: 3 }).run();
      },
    }),
    CustomCommandExtension.extend({ name: `bullet List` }).configure({
      slashCommandName: 'bullet List',
      commandEntered: () => {
        editor.value.chain().focus().toggleBulletList().run();
      },
    }),
    CustomCommandExtension.extend({ name: `ordered List` }).configure({
      slashCommandName: 'ordered List',
      commandEntered: () => {
        editor.value.chain().focus().toggleOrderedList().run();
      },
    }),
    CustomCommandExtension.extend({ name: `code Block` }).configure({
      slashCommandName: 'codeblock',
      commandEntered: () => {
        editor.value.chain().focus().toggleCodeBlock().run();
      },
    }),
    Code,
    PasteMarkdownExtension,
    CodeBlock,
    Dropcursor,
    HardBreak,
    History,
    HorizontalRule,
    Italic,
    Link.configure({
      autolink: false,
      HTMLAttributes: { class: 'tiptap-link' },
    }),
    Strike,
    Blockquotefrom,
    Document,
    Text,
    CodeMarkExtension.configure({ HTMLAttributes: { class: 'code-span' } }),
  ];

  // Editor instance initiated
  editor.value = new Editor({
    extensions: extensions,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    addImage,
    branch: props.branch,
    editable: !props.readOnly,
    moveNodeUp: moveNodeUp,
    moveNodeDown: moveNodeDown,
    content: tiptapContentFromSwmContent(content.value, extensions),
  });

  const editorUpdateCallback = debounce(() => {
    content.value = inlineMarkdownContent(editor.value.getJSON().content, extensions);
    emit('markdown-changed', content.value);
  }, 100);

  editor.value.on('update', editorUpdateCallback);

  watch(
    () => props.markdown,
    (newVal) => {
      if (newVal[0].text !== content.value[0].text) {
        content.value = newVal;
        const tiptapContent = tiptapContentFromSwmContent(content.value, extensions);
        editor.value.commands.setContent(tiptapContent);
      }
    },
    { immediate: true, deep: true }
  );
});

function inlineMarkdownContent(tiptapContent, tiptapExtension) {
  const { convertToMarkdown } = useHTMLToMarkdown({
    smartPath: true,
    swimmLink: true,
    mention: true,
  });
  const nextInlineContent = {
    type: 'doc',
    content: [],
  };
  const markdown = [];
  for (let index = 0; index < tiptapContent.length; index++) {
    const contentSlice = tiptapContent[index];
    nextInlineContent.content.push(contentSlice);
  }
  const html = generateHTML(nextInlineContent, tiptapExtension);
  const md = convertToMarkdown(html);
  markdown.push({
    type: SwmCellType.Text,
    text: md,
  });
  return markdown;
}

function moveNodeDown(pos, node) {
  const nodeToMove = node.toJSON();
  const newPos = editor.value.state.doc.resolve(pos + node.nodeSize + 1).end(1) + 1;
  editor.value
    .chain()
    .deleteRange({ from: pos, to: pos + node.nodeSize })
    .command(({ commands, tr }) => {
      commands.insertContentAt(tr.mapping.map(newPos), nodeToMove);
      return true;
    })
    .run();
}

function moveNodeUp(pos, node) {
  const nodeToMove = node.toJSON();
  const newPos = editor.value.state.doc.resolve(pos - 1).start(1) - 1;
  editor.value
    .chain()
    .deleteRange({ from: pos, to: pos + node.nodeSize })
    .command(({ commands, tr }) => {
      commands.insertContentAt(tr.mapping.map(newPos), nodeToMove);
      return true;
    })
    .run();
}

function addImage(src) {
  const imageContent = { type: 'image', attrs: { src: src, width: 50 } };
  editor.value.commands.insertContent(imageContent);
}

function setSelectionAsLink(url: string) {
  if (!editor.value) {
    return;
  }

  const to = editor.value.state.selection.to;
  const from = editor.value.state.selection.from;
  const hasHttps = url.startsWith('http://') || url.startsWith('https://');
  try {
    // If there is no text selected - make sure the link is still inserted
    if (to === from) {
      editor.value?.commands.insertContent('<a href="' + url + '" target="_blank" >' + url + '</a>');
    } else {
      // Otherwise add a link mark to the text already selected
      editor.value
        ?.chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: hasHttps ? url : 'https://' + url, target: '_blank' })
        .run();
    }
  } catch (err) {
    logger.error(err, `set link error occurred: ${err.message}`, { service: 'MarkdownEditor' });
  }
}
</script>
<style scoped>
/* stylelint-disable */
.editor {
  position: relative;
}

.editor.editor-border {
  padding: 0 8px;
  border: 1px solid var(--color-border-default);
  border-radius: 4px;
}

.editor-content {
  word-break: break-all;
}

.editor-content :deep(.orderedList) {
  list-style: decimal;
}

.editor-content :deep(h1),
.editor-content :deep(h2),
.editor-content :deep(h3),
.editor-content :deep(h4),
.editor-content :deep(h5),
.editor-content:deep(h6),
.editor-content :deep(.heading) {
  margin: revert;
}

.editor-content :deep(h1),
.editor-content :deep(.heading-1) {
  padding: unset;
  font-size: var(--headline1);
  line-height: 3rem; /* 48px */
}

.editor-content :deep(h2),
.editor-content :deep(.heading-2) {
  font-size: var(--headline2);
  line-height: 2.5rem; /* 40px */
}

.editor-content :deep(h3),
.editor-content :deep(.heading-3) {
  font-size: var(--headline3);
  line-height: 2rem; /* 32px */
}

.editor-content :deep(strong) {
  font-weight: 800;
}

.editor-content :deep(a.tiptap-link) {
  color: var(--text-color-link);
}

.editor-content :deep(.ProseMirror:focus-visible),
.editor-content :deep(.ProseMirror.ProseMirror-focused) {
  outline: none;
}

.editor-content :deep(.ProseMirror) {
  max-width: 100%;
  word-break: break-word;
}

.editor-content :deep(.ProseMirror:focus) {
  outline: none;
}

.editor-content :deep(.ProseMirror blockquote) {
  padding-left: 1.5rem;
  border-left: 4px solid var(--color-border-default-strong);
}

.editor-content :deep(.ProseMirror code) {
  font-family: var(--fontfamily-secondary);
  max-width: 100%;
  display: inline-block;
  margin: 1px 0;
  padding: 4px 10px;
  border-radius: 4px;
  background-color: var(--color-surface);
}

.editor-content :deep(.ProseMirror code:not(.codeBlock)) {
  margin: 0;
  padding: 2px 5px;
}
/* stylelint-enable */
</style>
